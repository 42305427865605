<template>
  <div id="knowledge_college">
    <!-- 企学院 -->
    <div class="knowledge-college-screen">
      <div class="knowledge-college-screen-search">
        <el-input
          placeholder="请输入题目内容"
          v-model="searchData.title"
          @keyup.enter.native="resetInitTableData"
          clearable
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click.native.stop="resetInitTableData"
          ></el-button>
        </el-input>
      </div>
      <div class="knowledge-college-screen-search">
        <el-select
          v-model="searchData.type"
          placeholder="请选择题目类型"
          @change="resetInitTableData"
        >
          <el-option
            v-for="item in examinationTypeList"
            :key="item.label"
            :label="item.label"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </div>
      <div class="knowledge-college-screen-active"></div>
      <div
        v-if="tabIndex == 0"
        class="knowledge-college-screen-but-select"
        v-showFunction="{ active: 'addQuestion', array: allFunctionButtons }"
        @click="handleClick({}, 'add')"
      >
        新增试题
      </div>
      <div
        class="knowledge-college-screen-but-select"
        v-if="tabIndex == 1"
        v-showFunction="{ active: 'addTestPaper', array: allFunctionButtons }"
        @click="handleClick({}, 'addTestPaper')"
      >
        新增试卷
      </div>
    </div>
    <div class="knowledge-college-table">
      <div class="knowledge-college-table-card">
        <div class="knowledge-college-table-card-tabs">
          <div
            :class="{
              'knowledge-college-table-card-tab': true,
              'is-active': tabIndex == 0,
            }"
            @click="changeTab(0)"
          >
            考试试题
          </div>
          <div
            :class="{
              'knowledge-college-table-card-tab': true,
              'is-active': tabIndex == 1,
            }"
            @click="changeTab(1)"
          >
            考试试卷
          </div>
        </div>
        <!-- 考试试题 -->
        <div class="knowledge-college-table-card-content" v-if="tabIndex == 0">
          <div class="knowledge-college-table-card-content-left">
            <el-tree
              :props="props"
              :load="initTableData"
              lazy
              node-key="id"
              :data="tagList"
              :default-expanded-keys="activeId"
            >
              <template #default="{ data, node }">
                <el-row
                  style="width: 100%; height: 100%"
                  type="flex"
                  align="middle"
                  justify="space-between"
                  @click.native="nodeClick(data, node)"
                >
                  <el-col :span="21" style="text-align: left;">
                    <div class="el-tree-title">
                      <span style="margin-right: 5px"
                        ><i
                          class="el-icon-folder-opened"
                          v-if="node.level === 1"
                        ></i>
                        <i class="el-icon-document" v-if="node.level === 3"></i
                      ></span>
                      <span class="name"> {{ data.name }}</span>
                    </div>
                  </el-col>
                  <el-col :span="3" v-if="node.level === 3">
                    <i
                      class="el-icon-delete"
                      style="color: #F56C6C"
                      v-showFunction="{
                        active: 'deleteQuestion',
                        array: allFunctionButtons,
                      }"
                      @click.stop="handleClick(data, 'delete')"
                    ></i>
                  </el-col>
                </el-row>
              </template>
            </el-tree>
          </div>
          <div class="knowledge-college-table-card-content-right">
            <examinationAdd
              v-if="formData.id"
              :examinationInfo="searchData.categoryList"
              :formData="formData"
              :allFunctionButtons="allFunctionButtons"
              :btnFlag="true"
              @addExamination="addExamination"
              @cancelExamination="cancelExamination"
            ></examinationAdd>
          </div>
        </div>
        <!-- 考试试卷 -->
        <div class="knowledge-college-table-card-content" v-if="tabIndex == 1">
          <el-table
            :data="tableDataByTestPaper"
            highlight-current-row
            style="width: 100%, overflow-x:auto"
            height="98%"
            v-loading="loading"
            row-key="id"
            :row-style="{ height: '60px' }"
          >
            <el-table-column type="index" width="40"></el-table-column>
            <el-table-column prop="title" label="试卷标题"></el-table-column>
            <el-table-column
              prop="enterpriseCollegeCategoryName"
              label="试卷分类"
            ></el-table-column>
            <el-table-column prop="duration" label="考试时间"></el-table-column>
            <el-table-column
              prop="totalScore"
              label="理论总分"
            ></el-table-column>
            <el-table-column
              prop="passingScore"
              label="考试及格分"
            ></el-table-column>

            <el-table-column label="操作" width="280">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="primary"
                  plain
                  icon="el-icon-position"
                  @click="handleClick(scope.row, 'issue')"
                  v-showFunction="{
                    active: 'issueTestPaper',
                    array: allFunctionButtons,
                  }"
                  >发布试卷</el-button
                >
                <el-button
                  size="mini"
                  type="success"
                  plain
                  icon="el-icon-edit-outline"
                  @click="handleClick(scope.row, 'edit')"
                  v-showFunction="{
                    active: 'editTestPaper',
                    array: allFunctionButtons,
                  }"
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  plain
                  icon="el-icon-edit-outline"
                  @click="handleClick(scope.row, 'deletePaper')"
                  v-showFunction="{
                    active: 'deleteTestPaper',
                    array: allFunctionButtons,
                  }"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="knowledge-college-table-card-bottom" v-if="tabIndex == 1">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
        @buttonOper="drawerOper"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import my_dialog from "@/components/dialog.vue";
import * as systemData from "@/assets/js/systemAuthorityData.js";
import * as knowledgeData from "@/assets/js/knowledgeData.js";
export default {
  components: {
    my_dialog,
    examinationAdd: () => import("./components/examination_add.vue"),
  },

  data() {
    return {
      // 考试试题
      baseInfo: {},
      loading: true,
      searchData: {
        title: "", // 类型名称
        type: "",
        search: "", // 搜索内容/标题
        time: [], // 时间段
        departTreeData: [],
        categoryList: [],
        userIdList: [],
        userList: [],
      },
      tagList: [],
      examinationTypeList: this.$examinationTypeList,
      dialogData: {
        flag: false,
        width: "35%",
        title: "新建",
        buttonArray: [
          {
            text: "提交",
            active: "submit_gnjinjilu",
          },
        ],
        data: [],
      },
      tabIndex: 0,
      tempData: {},
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "knowledgeExamination"
      ),
      props: {
        label: "name",
        isLeaf: "leaf",
      },
      tempData: {},
      node: {},
      resolve: {},
      activeId: "",
      formData: {
        type: "", // 题型 （单选题、多选题、判断题）
        enterpriseCollegeCategoryId: "", // 企学院类别 id
        title: "", // 题干
        options: [],
        answer: "", // 参考答案
        analyse: "", // 试题分析
      },
      formData2: {},
      // 考试试卷
      tableDataByTestPaper: [],
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
    };
  },
  methods: {
    /**
     * 获取界面的按钮权限
     */
    getModelFeatureAuthority() {
      this.$authority.getModelFeatureAuthority(
        this,
        "knowledgeExamination",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          this.getDepartmentTreeByUserId();
        }
      );
    },
    /**
     * 获取部门
     */
    getDepartmentTreeByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        "/department/getDepartmentTreeByUserId",
        requestData,
        (data) => {
          this.searchData.departTreeData = data;
          this.getEmployeeList();
          this.getTreeList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getEmployeeList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListForDownBox",
        requestData,
        (data) => {
          this.searchData.userList = [];
          data.forEach((element) => {
            let dd = {
              label: element.userName,
              value: element.userId,
            };
            this.searchData.userIdList.push(element.userId);
            this.searchData.userList.push(dd);
          });
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取企学院分类
     */
    getTreeList() {
      let requestData = {
        flag: true,
      };
      this.$request(
        "post",
        "/enterpriseCollegeCategory/getPureTreeList",
        requestData,
        (data) => {
          if (data.length > 0) {
            this.searchData.categoryList = data.map((person) => {
              let { name, ...rest } = person; // 解构赋值，提取 name
              return {
                label: name, // 改为 label
                ...rest,
                children: person.children
                  ? person.children.map((child) => {
                      let { name, id } = child; // 对孩子也进行同样的操作
                      return {
                        label: name, // 改为 label
                        id: id,
                      };
                    })
                  : [], // 处理没有 children 的情况
              };
            });
          }
        }
      );
    },
    /**
     * 初始化考试试题
     */
    initTableData(node, resolve) {
      console.log("node.level", node);
      this.node = node;
      this.resolve = resolve;
      let requestData = {};
      switch (node.level) {
        case 0:
          // 一级
          this.tagList = [];
          this.activeId = [];
          requestData = {
            userId: this.baseInfo.userId,
            companyId: this.baseInfo.company.companyId,
            flag: true,
          };
          this.$request(
            "post",
            "/enterpriseCollegeCategory/getTreeList",
            requestData,
            (data) => {
              if (data.length > 0) {
                this.tagList = data;
                // 收缩项有值 内容全部展开
                if (this.searchData.keyword) {
                  this.tagList.forEach((item) => {
                    this.activeId.push(item.id);
                    item.children.forEach((child) => {
                      this.activeId.push(child.id);
                    });
                  });
                }
              }
              return resolve(this.tagList);
            }
          );
          break;
        case 1:
          // 二级
          return resolve(node.data.children);
          break;
        case 2:
          // 三级
          requestData = {
            enterpriseCollegeCategoryId: node.data.id, // 筛选项：企学院类别 id
            title: this.searchData.title, // 筛选项：标题
            type: this.searchData.type, // 筛选项：题目类型（单选题、多选题、判断题）
            currentPage: -1, // 当前页数
            pageSize: -1, // 每页条数
          };
          this.$request(
            "post",
            "/examQuestion/query",
            requestData,
            (data) => {
              if (data.records.length > 0) {
                data.records.forEach((item) => {
                  item.leaf = true;
                  item.name = item.title;
                  if (item.type == "多选题") {
                    item.answer = item.answer.split(",");
                  }
                });
              }
              return resolve(data.records);
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        default:
          break;
      }
    },
    /**
     * 初始化考试试卷
     */
    initTableDataByTestPaper() {
      this.loading = true;
      let requestData = {
        currentPage: this.pageData.currentPage, // 当前页
        pageSize: this.pageData.pageSize, // 每页条数
      };
      this.$request(
        "post",
        "/examPaper/page",
        requestData,
        (data) => {
          this.pageData.total = data.total;
          this.tableDataByTestPaper = data.records;
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    refreshInitTableData() {
      this.node.childNode = [];
      this.initTableData(this.node, this.resolve);
    },
    resetInitTableData() {
      this.node.childNode = [];
      this.initTableData({ level: 0 }, this.resolve);
    },
    nodeClick(data, node) {
      if (node.level === 3) {
        this.formData = JSON.parse(JSON.stringify(data));
        this.formData2 = JSON.parse(JSON.stringify(data));
        delete this.formData.name;
        delete this.formData2.name;
      }
    },
    /**s
     * 表格按钮点击事件
     */
    handleClick(item, active) {
      this.tempData = item;
      let requestData = {};
      switch (active) {
        case "add":
          // 添加考试试题
          this.dialogData.width = "1000px";
          this.dialogData.title = "新增试题";
          this.dialogData.buttonArray = [
            {
              text: "提交",
              active: "sure_add",
            },
            { text: "取消", active: "cancel" },
          ];
          this.dialogData.data = [
            {
              type: "examinationAdd",
              categoryList: this.searchData.categoryList,
              formData: {
                type: "", // 题型 （单选题、多选题、判断题）
                enterpriseCollegeCategoryId: "", // 企学院类别 id
                title: "", // 题干
                options: [],
                answer: "", // 参考答案
                analyse: "", // 试题分析
              },
              btnFlag: false,
              allFunctionButtons: this.allFunctionButtons,
            },
          ];
          this.dialogData.flag = true;
          break;
        case "addTestPaper":
          // 添加考试试卷
          this.dialogData.width = "1000px";
          this.dialogData.title = "新增试卷";
          this.dialogData.buttonArray = [
            {
              text: "提交",
              active: "submit_addTestPaper",
            },
            { text: "取消", active: "cancel" },
          ];
          this.dialogData.data = [
            {
              type: "testPaper",
              value: {
                data: {
                  title: "", // 试卷标题
                  description: "", // 试卷描述
                  totalScore: "", // 总分
                  passingScore: "", // 及格分
                  duration: "", // 时长（分钟）
                  enterpriseCollegeCategoryId: "", // 企学院分类id
                  examPaperRules: [
                    {
                      type: "", // 题目类型（单选题、多选题、判断题）
                      count: "", // 数量
                      score: "", // 分值
                    },
                  ], // 试卷规则
                }, // 数据
                configuration: {
                  categoryList: this.searchData.categoryList, // 企学院分类
                }, // 配置项
              },
            },
          ];
          this.dialogData.flag = true;
          break;
        case "edit":
          this.dialogData.width = "1000px";
          this.dialogData.title = "编辑试卷规则";
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "submit_editTestPaper",
            },
            { text: "取消", active: "cancel" },
          ];
          this.dialogData.data = [
            {
              type: "testPaper",
              value: {
                data: {
                  title: this.tempData.title, // 试卷标题
                  description: this.tempData.description, // 试卷描述
                  totalScore: this.tempData.totalScore, // 总分
                  passingScore: this.tempData.passingScore, // 及格分
                  duration: this.tempData.duration, // 时长（分钟）
                  enterpriseCollegeCategoryId: this.tempData
                    .enterpriseCollegeCategoryId, // 企学院分类id
                  examPaperRules: this.tempData.examPaperRules, // 试卷规则
                }, // 数据
                configuration: {
                  categoryList: this.searchData.categoryList, // 企学院分类
                }, // 配置项
              },
            },
          ];
          this.dialogData.flag = true;
          break;
        case "issue":
          // 发布试卷
          this.dialogData.width = "800px";
          this.dialogData.title = "发布试卷-选择人员";
          this.dialogData.buttonArray = [
            {
              text: "确认",
              active: "sure_issueTestPaper",
            },
            { text: "取消", active: "cancel" },
          ];
          this.dialogData.data = [
            {
              title: "选择发布人员",
              type: "select-object",
              value: [],
              options: this.searchData.userList,
              collapseTags: true,
            },
          ];
          this.dialogData.flag = true;
          break;
        case "delete":
          this.$confirm("此操作将永久删除该试题, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$request(
                "get",
                "/examQuestion/del?id=" + this.tempData.id,
                {},
                (data) => {
                  this.$message.success("删除成功!");
                  this.refreshInitTableData();
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            })
            .catch(() => {});
          break;
        case "deletePaper":
          this.$confirm("此操作将永久删除该试卷, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$request(
                "get",
                "/examPaper/del?examPaperId=" + this.tempData.id,
                {},
                (data) => {
                  this.$message.success("删除成功!");
                  this.initTableDataByTestPaper();
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            })
            .catch(() => {});
          break;
        default:
          break;
      }
    },
    /**
     * dialog 按钮操作事件
     */
    drawerOper(active) {
      let flag = true;
      let requestData = {};
      let dialogData = this.dialogData.data[0].value;
      switch (active) {
        case "sure_add":
          // 确认添加考试试题
          requestData = this.dialogData.data[0].formData;
          requestData.answer = [...requestData.answer].sort().toString();
          flag = this.$validateData(requestData);
          // 试题分析 不做必填校验
          if (flag) {
            this.$message.error("请将题目信息填写完整!");
            return;
          }
          this.$request(
            "post",
            "/examQuestion/add",
            requestData,
            (data) => {
              this.$message.success("添加成功!");
              this.dialogData.flag = false;
              this.refreshInitTableData();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "submit_addTestPaper":
          // 确认添加考试试卷
          requestData = dialogData.data;
          requestData.description = "暂无";
          requestData.examPaperRules.forEach((item, index) => {
            item.sort = index; // 排列顺序
            item.enterpriseCollegeCategoryId =
              requestData.enterpriseCollegeCategoryId; // 企学院分类id
          });
          flag = this.$validateData(requestData);
          if (flag) {
            this.$message.error("请将试卷信息填写完整!");
            return;
          }
          this.$request(
            "post",
            "/examPaper/add",
            requestData,
            (data) => {
              this.$message.success("新增试卷成功!");
              this.dialogData.flag = false;
              this.initTableDataByTestPaper();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "submit_editTestPaper":
          requestData = dialogData.data;
          requestData.id = this.tempData.id;
          requestData.examPaperRules.forEach((item, index) => {
            item.sort = index; // 排列顺序
            item.enterpriseCollegeCategoryId =
              requestData.enterpriseCollegeCategoryId; // 企学院分类id
          });
          flag = this.$validateData(requestData);
          if (flag) {
            this.$message.error("请将试卷信息填写完整!");
            return;
          }
          this.$request(
            "post",
            "/examPaper/update",
            requestData,
            (data) => {
              this.$message.success("编辑试卷成功!");
              this.dialogData.flag = false;
              this.initTableDataByTestPaper();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "sure_issueTestPaper":
          // 发布试卷
          let requestData = {
            examPaperId: this.tempData.id, // 试卷规则 id
            userIds: dialogData, // 用户 id 集合
          };
          requestData.userIds = requestData.userIds.map((item) => item.value);
          this.$request(
            "post",
            "/examPaper/pub",
            requestData,
            (data) => {
              this.$message.success("发布成功!");
              this.dialogData.flag = false;
              this.initTableDataByTestPaper();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "cancel":
          this.colseDialog();
          break;
        default:
          break;
      }
    },
    /**
     * 切换tab
     */
    changeTab(index) {
      this.tabIndex = index;
      if (index == 0) {
      } else {
        // 初始化考试试卷
        this.initTableDataByTestPaper();
      }
    },
    /**
     * 添加 编辑 考试题目
     */
    addExamination(formData) {
      let requestData = {};
      // 编辑考试题目
      requestData = JSON.parse(JSON.stringify(this.formData));
      requestData.answer = [...requestData.answer].sort().toString();
      requestData.id = this.formData.id;
      let flag = this.$validateData(requestData);
      // 试题分析 不做必填校验
      if (flag) {
        this.$message.error("请将题目信息填写完整!");
        return;
      }
      // 如果两次修改分类一致 则不传分类id
      if (
        this.formData.enterpriseCollegeCategoryId ==
        this.formData2.enterpriseCollegeCategoryId
      ) {
        requestData.enterpriseCollegeCategoryId = null;
      }
      this.$request(
        "post",
        "/examQuestion/edit",
        requestData,
        (data) => {
          this.$message.success("编辑成功!");
          this.refreshInitTableData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    cancelExamination() {
      this.formData = {
        type: "", // 题型 （单选题、多选题、判断题）
        enterpriseCollegeCategoryId: "", // 企学院类别 id
        title: "", // 题干
        options: [],
        answer: [], // 参考答案
        analyse: "", // 试题分析
      };
      this.editorFlag = false;
    },

    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableDataByTestPaper();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableDataByTestPaper();
    },
    /**
     * dialog 关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
  },
  mounted() {
    this.loading = true;
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
  },
};
</script>

<style lang="less" scoped>
#knowledge_college {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .knowledge-college-screen {
    width: 100%;
    height: 45px;
    border-bottom: 1px solid @boderColor;
    display: flex;
    align-items: center;
    .knowledge-college-screen-fiftrate {
      width: 65px;
      min-width: 65px;
      height: 30px;
      border: 1px solid @boderColor;
      box-sizing: border-box;
      color: @boderActiveColor;
      font-size: @contentFontSize;
      margin: 0 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      transition: 0.3s;
      i {
        margin: 0 4px;
      }
    }
    .knowledge-college-screen-fiftrate:hover {
      cursor: pointer;
      border-color: @boderActiveColor;
    }
    .knowledge-college-screen-search {
      width: 210px;
      min-width: 210px;
      height: 30px;
      margin-left: 5px;
    }
    .knowledge-college-screen-checked {
      width: 140px;
      height: 30px;
      min-width: 140px;
      font-size: @contentFontSize;
      margin: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .knowledge-college-screen-active {
      flex: 1;
    }
    .knowledge-college-screen-but-select {
      width: 140px;
      min-width: 160px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: @activeColor;
      font-size: @contentFontSize;
      color: #fff;
      margin: 0 5px;
      border-radius: 4px;
      transition: 0.5s;
      cursor: pointer;
      .knowledge-college-screen-but-select_but {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.5s;
      }
      .knowledge-college-screen-but-select_but:hover {
        background-color: @activeColor2;
      }
      .knowledge-college-screen-but-select_select {
        width: 30px;
        height: 100%;
        border-left: 1px solid @activeColor3;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .knowledge-college-screen-but {
      width: 100px;
      min-width: 100px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: @activeColor;
      font-size: @contentFontSize;
      color: #fff;
      margin: 0 5px;
      border-radius: 4px;
      transition: 0.5s;
    }
    .knowledge-college-screen-but:hover {
      cursor: pointer;
      background-color: @activeColor2;
    }
    .knowledge-college-screen-but-boder {
      width: 50px;
      min-width: 50px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid @activeColor;
      font-size: @contentFontSize;
      background-color: @activeBackground;
      color: @activeColor;
      margin: 0 5px;
      margin-right: 10px;
      border-radius: 4px;
      transition: 0.5s;
    }
    .knowledge-college-screen-but-boder:hover {
      cursor: pointer;
      background-color: @activeBackground2;
    }
  }
  .knowledge-college-table {
    flex: 1;
    width: 100%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;
    position: relative;
    .knowledge-college-table-card-content-tip {
      width: 98%;
      height: 4vh;
      position: absolute;
      top: 2vh;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .knowledge-college-table-card-active {
        flex: 1;
      }
      .knowledge-college-table-card-edit {
        width: 30px;
        min-width: 30px;
        margin-right: 10px;
        z-index: 99;
        display: flex;
        align-items: center;
        justify-content: center;
        color: @editColor;
        cursor: pointer;
      }
    }
    .knowledge-college-table-card {
      width: 98%;
      height: 95%;
      // max-height: 830px;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .knowledge-college-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        .knowledge-college-table-card-tab {
          width: 80px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .knowledge-college-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
        .knowledge-college-table-card-active {
          flex: 1;
        }
        .knowledge-college-table-card-edit {
          width: 30px;
          min-width: 30px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: @editColor;
          cursor: pointer;
        }
      }
      .knowledge-college-table-card-content {
        flex: 1;
        width: 98%;
        height: 0;
        display: flex;
        align-content: center;
        justify-content: center;
        font-size: @contentFontSize;

        .knowledge-college-table-card-content-left {
          width: 30%;
          border-right: 1px solid @boderColor;
          overflow-y: auto;
          .el-tree-title {
            display: flex;
            align-items: center;
            .name {
              width: 250px;
              text-overflow: ellipsis;
              overflow: hidden;
              word-break: break-all;
              white-space: nowrap;
            }
          }
          &::-webkit-scrollbar {
            width: 1px;
          }
        }
        .knowledge-college-table-card-content-right {
          width: 70%;
          padding: 15px;
          box-sizing: border-box;
          .right-line {
            display: flex;
            width: 100%;
            padding: 10px;
            box-sizing: border-box;
            margin-bottom: 15px;
            border-radius: 3px;
            white-space: pre-wrap;
            .icon {
              width: 80px;
              display: flex;
              justify-content: space-evenly;
            }
            .content {
              display: flex;
              flex-direction: column;
              width: 100%;
              align-items: flex-start;
              text-align: left;
              .title {
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 10px;
              }
              .time {
                color: #979494;
              }
              .imgs {
                display: flex;
                .img {
                  width: 100px;
                  height: 100px;
                  cursor: pointer;
                  margin-left: 10px;
                  margin-bottom: 10px;
                }
              }
              .documents {
                display: flex;
                flex-direction: column;
                align-items: center;
                img {
                  width: 100px;
                  height: 100px;
                  margin-bottom: 10px;
                  cursor: pointer;
                }
              }
            }
          }
          .question {
            background: #ecf5ff;
            span {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              color: #fff;
              background-color: #409eff;
            }
          }
          .answer {
            background: #f3f5f8;
            span {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              color: #fff;
              background-color: #b6bcca;
            }
          }
        }
      }
      .knowledge-college-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
        .el-input__inner {
          width: 100px !important;
        }
      }
    }
  }
  .hri {
    display: flex;
    i {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 3px;
      font-size: 14px;
      color: @activeColor;
    }
  }
}
</style>
