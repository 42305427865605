<template>
  <!-- 考试记录 全部 -->
  <div id="knowledge-record-all">
    <div class="knowledge-record-all-screen">
      <div class="knowledge-record-all-screen-line">
        <div class="knowledge-record-all-screen-search">
          <el-input
            placeholder="请输入试卷标题"
            v-model="searchData.title"
            @keyup.enter.native="resetPage"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="resetPage"
            ></el-button>
          </el-input>
        </div>
        <div class="knowledge-record-all-screen-search">
          <el-cascader
            v-model="searchData.department"
            placeholder="请选择部门"
            :options="searchData.departTreeData"
            @change="resetPage"
            clearable
            :show-all-levels="false"
            :props="{ checkStrictly: true, value: 'id' }"
            filterable
          ></el-cascader>
        </div>
        <div class="knowledge-record-all-screen-search">
          <el-select
            v-model="searchData.userId"
            @change="selectInitTableData"
            filterable
          >
            <el-option
              v-for="item in searchData.userList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="knowledge-record-all-screen-search">
          <el-select
            v-model="searchData.isPass"
            @change="resetPage"
            filterable
            clearable
            placeholder="请选择及格标识"
          >
            <el-option label="及格" value="及格"></el-option>
            <el-option label="不及格" value="不及格"></el-option>
          </el-select>
        </div>
        <div class="knowledge-record-all-screen-active"></div>
      </div>
    </div>
    <div class="table-content" v-if="!examFlag">
      <div class="knowledge-record-all-table-card">
        <div class="knowledge-record-all-content">
          <el-table
            :data="tableData"
            highlight-current-row
            style="width: 100%, overflow-x:auto"
            height="98%"
            v-loading="loading"
            row-key="id"
            :row-style="{ height: '60px' }"
          >
            <el-table-column type="index" width="40"></el-table-column>
            <el-table-column
              prop="userName"
              label="考生姓名"
              width="120"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="title"
              label="试卷标题"
              width="200"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="enterpriseCollegeCategoryName"
              label="试卷分类"
              width="180"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="totalScore"
              label="理论总分"
              width="110"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="passingScore"
              label="考试及格分"
              width="110"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="userScore"
              label="考试成绩"
              width="110"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column prop="isPass" label="是否及格" width="110">
              <template slot-scope="scope">
                <span v-if="scope.row.isPass == '及格'" style="color: #67C23A"
                  >及格</span
                >
                <span v-else style="color: #F56C6C">不及格</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="gmtCreate"
              label="答题时间"
              width="180"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="takeDuration"
              label="用时（分钟）"
              show-overflow-tooltip
            ></el-table-column>

            <el-table-column label="操作" width="100" fixed="right">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="success"
                  plain
                  @click="handleClick(scope.row, 0)"
                  v-showFunction="{ active: 'look', array: allFunctionButtons }"
                  >查看试卷</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="knowledge-record-all-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="table-content" v-else>
      <div class="knowledge-record-all-table-card">
        <div class="knowledge-record-all-content">
          <exam
            :examInfo="examInfo"
            @submitExam="submitExam"
            @returnExam="returnExam"
          ></exam>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import * as expressageData from "@/views/expressage/js/expressage.js";
import my_drawer from "@/components/my-drawer.vue";
import my_dialog from "@/components/dialog.vue";
import exam from "./components/exam.vue";
export default {
  components: {
    my_drawer,
    my_dialog,
    exam,
  },
  data() {
    return {
      baseInfo: {},
      whetherAgencyHead: false,
      loading: false,
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "knowledgeRecordAll"
      ),
      tableData: [],
      searchData: {
        title: "",
        isPass: null,
        screenList: [],
        department: "",
        departTreeData: [],
        userId: "",
        userIdList: [],
        userList: [],
        tempUserId: [],
      },
      tempData: {},
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      dialogData: {
        flag: false,
        width: "30%",
        title: "新增档案",
        buttonArray: [
          {
            text: "确认",
            active: "confirm",
          },
        ],
        data: [],
      },
      examFlag: false,
      examInfo: {},
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "knowledgeRecordAll",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          this.isCharge();
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;

        this.getDepartmentTreeByUserId();
        // this.getEmployeeListForDownBox();
      });
    },
    // /**
    //  * 获取所有人员列表
    //  */
    // getEmployeeListForDownBox() {
    //   let requestData = {
    //     companyId: this.baseInfo.company.companyId,
    //   };
    //   this.$request(
    //     "post",
    //     "/user/getEmployeeListForDownBox",
    //     requestData,
    //     (data) => {
    //       this.searchData.allUserList = data;
    //       this.searchData.allUserList.splice(0, 0, {
    //         userId: "",
    //         userName: "全部人员",
    //       });
    //     }
    //   );
    // },
    /**
     * 获取部门
     */
    getDepartmentTreeByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        "/department/getDepartmentTree",
        requestData,
        (data) => {
          this.searchData.departTreeData = [data];
          this.getEmployeeList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getEmployeeList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListForDownBox",
        requestData,
        (data) => {
          this.searchData.userList = [];
          data.forEach((element) => {
            let dd = {
              name: element.userName,
              id: element.userId,
            };
            this.searchData.userIdList.push(element.userId);
            this.searchData.userList.push(dd);
          });
          this.searchData.userList.splice(0, 0, {
            id: "all",
            name: "全部人员",
          });
          this.searchData.userId = "all";
          this.selectInitTableData("all");
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取表格界面数据
     */
    initTableData() {
      this.loading = true;
      let requestData = {
        department:
          this.searchData.department.length > 0
            ? this.searchData.department[this.searchData.department.length - 1]
            : "",
        userIds: this.searchData.tempUserId, // 员工id集合
        title: this.searchData.title, // 筛选项：标题
        isPass: this.searchData.isPass, // 筛选项 是否及格。及格、不及格
        currentPage: this.pageData.currentPage, // 当前页
        pageSize: this.pageData.pageSize, // 每页条数
      };
      this.$request(
        "post",
        "/examRecordsPaper/page",
        requestData,
        (data) => {
          this.pageData.total = data.total;
          this.tableData = data.records;
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
    /**
     * 界面操作
     * batch 是否为批量操作
     */
    handleClick(item, flag) {
      this.tempData = item;
      switch (flag) {
        case 0:
          // 查看考试试卷
          this.$request(
            "get",
            "/examRecordsPaper/getInfo?id=" + this.tempData.id,
            {},
            (data) => {
              this.examInfo = data;
              this.examInfo.examQuestions.forEach((item) => {
                item.selected = item.selected.split(",");
              });
              console.log("this.examInfo", this.examInfo);
              this.examFlag = true;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          return;

          break;
        default:
          break;
      }
    },
    submitExam() {},
    returnExam() {
      this.examFlag = false;
    },
    /**
     * 抽屉数据或dialog数据操作事件
     */
    drawerOper(active) {
      let requestData = {};
      switch (active) {
        default:
          break;
      }
    },
    /**
     * 员工选择
     */
    selectInitTableData(val) {
      if (val == "all") {
        this.searchData.tempUserId = this.searchData.userIdList;
      } else {
        this.searchData.tempUserId = [val];
      }
      this.resetPage();
    },

    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
      this.isEdit = false;
      this.isCopy = false;
    },
    resetPage() {
      this.pageData.currentPage = 1;
      this.initTableData();
    },
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
  },
  mounted() {
    this.searchData.userId = "all";
  },
};
</script>

<style lang="less" scoped>
#knowledge-record-all {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .knowledge-record-all-screen {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    .knowledge-record-all-screen-line {
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      > .title {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        padding: 0 10px;
      }
      > .el-tag {
        margin: 0 5px;
      }
      .knowledge-record-all-screen-line-fiftrate {
        width: 65px;
        min-width: 65px;
        height: 30px;
        border: 1px solid @boderColor;
        box-sizing: border-box;
        color: @boderActiveColor;
        font-size: @contentFontSize;
        margin: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        transition: 0.3s;
        i {
          margin: 0 4px;
        }
      }
      .knowledge-record-all-screen-line-fiftrate:hover {
        cursor: pointer;
        border-color: @boderActiveColor;
      }
      .knowledge-record-all-screen-search {
        width: 210px;
        min-width: 210px;
        height: 30px;
        margin-left: 10px;
      }
      .knowledge-record-all-screen-active {
        flex: 1;
      }
      .drop-button {
        background-color: #ecf5ff;
        color: #409eff;
        transition: 0.5s;
        &:hover {
          background-color: #abbed3;
        }
      }
      .archives-screen-but-select {
        padding: 0px 52px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
        cursor: pointer;
      }
    }
  }

  .table-content {
    flex: 1;
    width: 100%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;

    .knowledge-record-all-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .knowledge-record-all-content {
        width: 100%;
        height: 100%;
        max-width: 90vw;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
      }
      .knowledge-college-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        .knowledge-college-table-card-tab {
          width: 80px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .knowledge-college-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
        .knowledge-college-table-card-active {
          flex: 1;
        }
        .knowledge-college-table-card-edit {
          width: 30px;
          min-width: 30px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: @editColor;
          cursor: pointer;
        }
      }
      .knowledge-college-table-card-content {
        position: relative;
        flex: 1;
        width: 98%;
        height: 0;
        display: flex;
        align-content: center;
        justify-content: center;
        font-size: @contentFontSize;

        .knowledge-college-table-card-content-left {
          width: 30%;
          border-right: 1px solid @boderColor;
          overflow-y: auto;
          .el-tree-title {
            display: flex;
            align-items: center;
            .name {
              width: 250px;
              text-overflow: ellipsis;
              overflow: hidden;
              word-break: break-all;
              white-space: nowrap;
            }
          }
          &::-webkit-scrollbar {
            width: 1px;
          }
        }
        .knowledge-college-table-card-content-right {
          display: flex;
          flex-direction: column;
          width: 70%;
          padding: 15px;
          box-sizing: border-box;
          .right-line {
            display: flex;
            width: 100%;
            padding: 10px;
            box-sizing: border-box;
            margin-bottom: 15px;
            border-radius: 3px;
            white-space: pre-wrap;
            .icon {
              width: 80px;
              display: flex;
              justify-content: space-evenly;
            }
            .content {
              display: flex;
              flex-direction: column;
              width: 100%;
              align-items: flex-start;
              text-align: left;
              .title {
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 10px;
              }
              .time {
                color: #979494;
              }
              .imgs {
                display: flex;
                .img {
                  width: 100px;
                  height: 100px;
                  cursor: pointer;
                  margin-left: 10px;
                  margin-bottom: 10px;
                }
              }
              .documents {
                display: flex;
                flex-direction: column;
                align-items: center;
                img {
                  width: 100px;
                  height: 100px;
                  margin-bottom: 10px;
                  cursor: pointer;
                }
              }
            }
          }
          .question {
            background: #ecf5ff;
            span {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              color: #fff;
              background-color: #409eff;
            }
          }
          .answer {
            background: #f3f5f8;
            // flex: 1;
            overflow-y: auto;
            &::-webkit-scrollbar {
              width: 3px;
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              background: rgba(0, 0, 0, 0.2);
            }
            span {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              color: #fff;
              background-color: #b6bcca;
            }
          }
        }
        .knowledge-college-table-card-content-button {
          position: absolute;
          bottom: 50px;
          right: 40px;
          width: 140px;
          min-width: 160px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: @activeColor;
          font-size: @contentFontSize;
          color: #fff;
          margin: 0 5px;
          border-radius: 4px;
          transition: 0.5s;
          cursor: pointer;
        }
      }
      .knowledge-record-all-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
