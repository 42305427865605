<template>
  <div id="exam">
    <div class="exam-title">{{ examInfo.title || "--" }}</div>
    <div class="exam-content">
      <div class="exam-content-left">
        <div class="left-line">
          <div class="left-line-icon"><i class="el-icon-notebook-2"></i></div>
          <div class="left-line-title">考试分类:</div>
          <div class="left-line-value">
            {{ examInfo.enterpriseCollegeCategoryName }}
          </div>
        </div>
        <div class="left-line">
          <div class="left-line-icon"><i class="el-icon-user"></i></div>
          <div class="left-line-title">考试姓名:</div>
          <div class="left-line-value">
            {{ examInfo.userName || baseInfo.userName }}
          </div>
        </div>
        <div class="left-line">
          <div class="left-line-icon"><i class="el-icon-time"></i></div>
          <div class="left-line-title">考试时间:</div>
          <div class="left-line-value">{{ examInfo.duration }} 分钟</div>
        </div>
        <div class="left-line">
          <div class="left-line-icon"><i class="el-icon-edit-outline"></i></div>
          <div class="left-line-title">试卷总分:</div>
          <div class="left-line-value grade">{{ examInfo.totalScore }} 分</div>
        </div>
        <div class="left-line" v-if="examInfo.status == '2'">
          <div class="left-line-icon"><i class="el-icon-edit-outline"></i></div>
          <div class="left-line-title">考试成绩:</div>
          <div class="left-line-value grade">{{ examInfo.userScore }} 分</div>
        </div>
      </div>
      <div class="exam-content-right">
        <div class="exam-list">
          <div
            class="exam-list-item"
            v-for="(item, index) in examInfo.examQuestions"
            :key="index"
          >
            <div class="exam-list-item-title">
              {{ index + 1 }}、
              <span class="title-type"
                >【{{ item.type }} / {{ item.score }} 分】</span
              >
              <span class="title-question">{{ item.title }}</span>
              <div class="title-answer">
                <div>(</div>
                <div>{{ item.selected.join(", ") }}</div>
                <div>)</div>
              </div>
            </div>
            <div class="exam-list-item-answer">
              <div
                :class="{
                  'answer-item': true,
                  selected: item.selected.includes(it.label),
                }"
                v-for="(it, ind) in item.options"
                :key="ind"
                @click="selectAnswer(item, it, ind)"
              >
                <span>{{ it.label }}、</span>{{ it.content }}
              </div>
            </div>
            <div class="exam-list-item-line" v-if="examInfo.status == '2'">
              <div class="line-title">【学员答案】:</div>
              <div class="line-value">{{ item.selected.join(", ") }}</div>
            </div>
            <div class="exam-list-item-line" v-if="examInfo.status == '2'">
              <div class="line-title">【正确答案】:</div>
              <div class="line-value2">{{ item.answer }}</div>
            </div>
            <div class="exam-list-item-line" v-if="examInfo.status == '2'">
              <div class="line-title">【得分】:</div>
              <div class="line-value2">{{ item.userScore }}分</div>
            </div>
            <div class="exam-list-item-line" v-if="examInfo.status == '2'">
              <div class="line-title">【试题解析】:</div>
              <div class="line-value2">{{ item.analyse }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="exam-button" @click="submitExam" v-if="examInfo.status == '1'">
      交卷
    </div>
    <div class="exam-button" @click="returnExam" v-if="examInfo.status == '2'">
      返回
    </div>
  </div>
</template>

<script>
export default {
  props: {
    examInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      baseInfo: {},
      loading: false,
    };
  },
  methods: {
    // 点击选项, 添加选项值
    selectAnswer(item, it, ind) {
      if (this.examInfo.status == "2") {
        return;
      }
      switch (item.type) {
        case "单选题":
          this.$set(item.selected, 0, it.label);
          break;
        case "多选题":
          if (item.selected.includes(it.label)) {
            item.selected.splice(item.selected.indexOf(it.label), 1);
          } else {
            item.selected.push(it.label);
          }
          // 字母编码排序
          item.selected.sort();
          break;
        case "判断题":
          this.$set(item.selected, 0, it.label);
          break;
        default:
          break;
      }
      console.log("item", item);
      console.log("it", it);
      console.log("ind", ind);
    },
    submitExam() {
      this.$emit("submitExam");
    },
    returnExam() {
      this.$emit("returnExam");
    },
  },
  mounted() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
  },
};
</script>

<style lang="less" scoped>
#exam {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  .exam-title {
    // height: 10%;
    font-size: 25px;
    font-weight: 700;
    padding: 20px 0;
    border-bottom: 1px solid #ccc;
  }
  .exam-content {
    display: flex;
    height: 90%;
    .exam-content-left {
      width: 20%;
      height: 100%;
      border-right: 1px solid #ccc;
      padding: 20px;
      box-sizing: border-box;
      font-size: 14px;
      .left-line {
        width: 100%;
        display: flex;
        align-items: baseline;
        margin-bottom: 25px;
        .left-line-icon {
        }
        .left-line-title {
          margin-left: 3px;
          margin-right: 5px;
        }
        .left-line-value {
        }
        .grade {
          color: #ee6110;
        }
      }
    }
    .exam-content-right {
      width: 80%;
      height: 100%;
      padding: 20px;
      box-sizing: border-box;
      overflow-y: auto;
      .exam-list {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 3px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: rgba(0, 0, 0, 0.2);
        }
        .exam-list-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-bottom: 25px;

          .exam-list-item-title {
            display: flex;
            align-items: center;
            font-size: 16px;
            margin-bottom: 15px;
            .title-answer {
              min-width: 50px;
              display: flex;
              justify-content: space-between;
              align-items: center;

              margin: 0 5px;
            }
          }
          .exam-list-item-answer {
            display: flex;
            flex-direction: column;
            .answer-item {
              width: fit-content;
              display: flex;
              align-items: center;
              margin-bottom: 15px;
              font-size: 16px;
              color: #9d9fa3;
              cursor: pointer;
              span {
                font-weight: 700;
              }
            }
            .selected {
              color: #67c23a;
            }
          }
          .exam-list-item-line {
            display: flex;
            align-items: baseline;
            margin-bottom: 15px;
            text-align: left;

            .line-title {
              white-space: nowrap;
            }
            .line-value {
              flex: 1;
              padding: 10px;
              background-color: #f4f9fc;
              border-radius: 5px;
              font-size: 16px;
            }
            .line-value {
              line-height: 1.5;
            }
          }
        }
      }
    }
  }
  .exam-button {
    position: absolute;
    bottom: 20px;
    right: 80px;
    width: 140px;
    min-width: 160px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @activeColor;
    font-size: @contentFontSize;
    color: #fff;
    margin: 0 5px;
    border-radius: 4px;
    transition: 0.5s;
    cursor: pointer;
  }
}
</style>
