import Vue from 'vue'

export default function request(method, url, sendData, deal, err) {
    Vue.axios({
            method: method,
            url: this.$baseurl + url,
            data: JSON.stringify(sendData),
            headers: {
                'Content-Type': 'application/json',
                'Content-Security-Policy': 'upgrade-insecure-requests',
                'Content-Security-Policy': 'block-all-mixed-content'
            }
        })
        .then(
            res => {
                switch (res.data.code) {
                    case 200:
                        deal(res.data.result)
                        break;
                    case 500:
                        // this.$message.error(res.data.message);
                        err(res.data.message, res.data.result)
                        break;
                    case 401:
                        // 登录失效
                        window.sessionStorage.removeItem("token");
                        this.$router.push("/");
                        this.$message.error(res.data.msg);
                        // err(res.data.message)
                        break;
                    default:
                        // this.$message.error(res.data.message);
                        err(res.data.message)
                        break;
                }
            }
        )
        .catch((e) => {
            // this.$message.error('系统错误');
            console.log("系统错误", url, e);
            err('系统错误')
        })

} 

