<template>
  <div id="wechat-index">
    <div class="wechat-client-content-index">
      <div class="wechat-client-content-index-line">
        <div class="wechat-client-content-index-line-top">
          <div class="wechat-client-content-index-line-title">标签</div>
        </div>
        <div
          class="wechat-client-content-index-line-value"
          style="display: flex; flex-wrap: wrap;"
        >
          <div class="tip" v-for="item in clientInfo.tip" :key="item">
            {{ item }}
          </div>
          <div class="none" v-if="clientInfo.tip.length === 0">暂无数据</div>
        </div>
      </div>
      <div class="wechat-client-content-index-line">
        <div class="wechat-client-content-index-line-top">
          <div class="wechat-client-content-index-line-title">任务</div>
          <div class="active"></div>
          <div class="wechat-client-content-index-line-time">
            <el-date-picker
              v-model="task.date"
              type="month"
              value-format="yyyy-MM"
              placeholder="选择月"
              :clearable="false"
              @change="getTask"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="wechat-client-content-index-line-value">
          <el-table
            :data="task.list"
            :header-cell-style="{
              background: '#F5F7FA',
              color: '#606266',
            }"
            style="width: 100%; font-size: 12px"
            v-loading="task.loading"
          >
            <el-table-column
              prop="scheduleTitle"
              label="日程名称"
              width="100px"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="scheduleContent"
              label="日程内容"
              width="120px"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column label="状态" width="60px" show-overflow-tooltip>
              <template slot-scope="scope">
                <span :style="{ color: scope.row.color }">{{
                  scope.row.statusName
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="startTime"
              label="开始时间"
              width="135px"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="endTime"
              label="结束时间"
              width="135px"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column label="操作" width="50px" fixed="right">
              <template slot-scope="scope">
                <el-popconfirm
                  title="确认完成该任务吗？"
                  @confirm="handleClick(scope.row, 'completionTask')"
                >
                  <el-button slot="reference" type="text" size="mini"
                    >完成</el-button
                  >
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div
        class="wechat-client-content-index-line"
        v-if="taxStatement.list.length > 0"
      >
        <div class="wechat-client-content-index-line-top">
          <div class="wechat-client-content-index-line-title">企业流水</div>
          <div class="active"></div>
          <div class="wechat-client-content-index-line-time">
            <el-date-picker
              v-model="taxStatement.year"
              type="year"
              value-format="yyyy"
              placeholder="选择年"
              :clearable="false"
              @change="getTaxStatement"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="wechat-client-content-index-line-value">
          <el-table
            class="taxStatement"
            :data="taxStatement.list"
            :header-cell-style="{
              background: '#F5F7FA',
              color: '#606266',
            }"
            style="width: 100%; font-size: 12px"
            v-loading="taxStatement.loading"
          >
            <el-table-column
              v-for="item in taxStatement.tableMenu"
              :key="item.value"
              :prop="item.value"
              :label="item.label"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row[item.value]"
                  :name="scope.row[item.value]"
                  @change="
                    changeItem($event, scope.row, item.value, scope.$index)
                  "
                >
                  <el-option
                    v-for="item in taxStatement.options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="wechat-client-content-index-line">
        <div class="wechat-client-content-index-line-top">
          <div class="wechat-client-content-index-line-title">合同期限</div>
        </div>
        <div
          class="wechat-client-content-index-line-value"
          v-if="contractDeadLine.list.length > 0"
        >
          <el-table
            :data="contractDeadLine.list"
            :header-cell-style="{
              background: '#F5F7FA',
              color: '#606266',
            }"
            :row-class-name="contractDeadLineRowClassName"
            style="width: 100%; font-size: 12px"
            v-loading="contractDeadLine.loading"
          >
            <el-table-column
              prop="contractNumber"
              label="合同编号"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="status"
              label="合同状态"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="collectionStatus"
              label="收款状态"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="money"
              label="合同金额"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="accruedAssets"
              label="已收款"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="auditedIncome"
              label="未收款"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="startToEnd"
              label="合同期限"
              width="150px"
              show-overflow-tooltip
            >
            </el-table-column>
          </el-table>
          <div
            class="more"
            v-if="!contractDeadLine.moreFlag"
            @click="toMore('contractDeadLine', -1)"
          >
            查看更多<i class="el-icon-arrow-down"></i>
          </div>
          <div class="more" v-else @click="toMore('contractDeadLine', 3)">
            收起更多<i class="el-icon-arrow-up"></i>
          </div>
        </div>
        <div class="wechat-client-content-index-line-value" v-else>
          <div class="none">暂无数据</div>
        </div>
      </div>
      <div class="wechat-client-content-index-line">
        <div class="wechat-client-content-index-line-top">
          <div class="wechat-client-content-index-line-title">资质到期</div>
        </div>
        <div
          class="wechat-client-content-index-line-value"
          v-if="qualification.list.length > 0"
        >
          <el-table
            :data="qualification.list"
            :header-cell-style="{
              background: '#F5F7FA',
              color: '#606266',
            }"
            style="width: 100%; font-size: 12px"
            v-loading="qualification.loading"
          >
            <el-table-column prop="name" label="资质名称" show-overflow-tooltip>
              <template slot-scope="scope">
                <span
                  class="baseColor"
                  @click="handleClick(scope.row, 'showQualification')"
                  >{{ scope.row.name }}</span
                >
              </template>
            </el-table-column>
            <el-table-column prop="type" label="资质类型" show-overflow-tooltip>
            </el-table-column>
            <el-table-column
              prop="startServiceDate"
              label="开始时间"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="endServiceDate"
              label="到期时间"
              show-overflow-tooltip
            >
            </el-table-column>
          </el-table>
          <div
            class="more"
            v-if="!qualification.moreFlag"
            @click="toMore('qualification', -1)"
          >
            查看更多<i class="el-icon-arrow-down"></i>
          </div>
          <div class="more" v-else @click="toMore('qualification', 3)">
            收起更多<i class="el-icon-arrow-up"></i>
          </div>
        </div>
        <div class="wechat-client-content-index-line-value" v-else>
          <div class="none">暂无数据</div>
        </div>
      </div>
      <div class="wechat-client-content-index-line">
        <div class="wechat-client-content-index-line-top">
          <div class="wechat-client-content-index-line-title">档案</div>
        </div>
        <div
          class="wechat-client-content-index-line-value"
          v-if="archives.list.length > 0"
        >
          <el-table
            :data="archives.list"
            :header-cell-style="{
              background: '#F5F7FA',
              color: '#606266',
            }"
            style="width: 100%; font-size: 12px"
            v-loading="archives.loading"
          >
            <el-table-column
              prop="name"
              label="录入物品"
              width="100px"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="location"
              label="录入位置"
              width="130px"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="createName"
              label="录入人"
              width="120px"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="status" label="状态" show-overflow-tooltip>
              <template slot-scope="scope">
                <span :style="{ color: scope.row.color }">{{
                  scope.row.status
                }}</span>
              </template>
            </el-table-column>
          </el-table>
          <div
            class="more"
            v-if="!archives.moreFlag"
            @click="toMore('archives', -1)"
          >
            查看更多<i class="el-icon-arrow-down"></i>
          </div>
          <div class="more" v-else @click="toMore('archives', 3)">
            收起更多<i class="el-icon-arrow-up"></i>
          </div>
        </div>
        <div class="wechat-client-content-index-line-value" v-else>
          <div class="none">暂无数据</div>
        </div>
      </div>
      <div class="wechat-client-content-index-line">
        <div class="wechat-client-content-index-line-top">
          <div class="wechat-client-content-index-line-title">快递</div>
        </div>
        <div
          class="wechat-client-content-index-line-value"
          v-if="expressage.list.length > 0"
        >
          <el-table
            :data="expressage.list"
            :header-cell-style="{
              background: '#F5F7FA',
              color: '#606266',
            }"
            style="width: 100%; font-size: 12px"
            v-loading="expressage.loading"
          >
            <el-table-column
              prop="deliveryId"
              label="运单号"
              width="130px"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="receiverName"
              label="收件人"
              width="60px"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="goods"
              label="物品"
              width="110px"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="orderDate"
              label="下单时间"
              width="140px"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="createName"
              label="下单员工"
              width="110px"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="payAmount"
              label="实扣运费"
              width="70px"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="deliveryState"
              label="订单状态"
              show-overflow-tooltip
            >
            </el-table-column>
          </el-table>
          <div
            class="more"
            v-if="!expressage.moreFlag"
            @click="toMore('expressage', -1)"
          >
            查看更多<i class="el-icon-arrow-down"></i>
          </div>
          <div class="more" v-else @click="toMore('expressage', 3)">
            收起更多<i class="el-icon-arrow-up"></i>
          </div>
        </div>
        <div class="wechat-client-content-index-line-value" v-else>
          <div class="none">暂无数据</div>
        </div>
      </div>
      <div class="wechat-client-content-index-line">
        <div class="wechat-client-content-index-line-top">
          <div class="wechat-client-content-index-line-title">发票</div>
        </div>
        <div class="wechat-client-content-index-line-value">
          <div class="none">暂无数据</div>
        </div>
      </div>
      <div class="wechat-client-content-index-line">
        <div class="wechat-client-content-index-line-top">
          <div class="wechat-client-content-index-line-title">财务数据</div>
          <div class="active"></div>
          <div class="wechat-client-content-index-line-time">
            <el-date-picker
              v-model="cost.date"
              type="month"
              value-format="yyyy-MM"
              placeholder="选择月"
              :clearable="false"
              @change="getCostInfo"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="wechat-client-content-index-line-value">
          <div class="tax-cost">
            <div class="tax-cost-item">
              <div class="tax-cost-item-title">近12个月营业收入:</div>
              <div class="tax-cost-item-value">{{ cost.info.nearlyYear }}</div>
            </div>
            <div class="tax-cost-item">
              <div class="tax-cost-item-title">年营业收入:</div>
              <div class="tax-cost-item-value">
                {{ cost.info.annualOperatingIncome }}
              </div>
            </div>
            <div class="tax-cost-item">
              <div class="tax-cost-item-title">年营业成本:</div>
              <div class="tax-cost-item-value">
                {{ cost.info.annualOperatingCost }}
              </div>
            </div>
            <div class="tax-cost-item">
              <div class="tax-cost-item-title">年营业费用:</div>
              <div class="tax-cost-item-value">
                {{ cost.info.annualOperatingExpense }}
              </div>
            </div>
            <div class="tax-cost-item">
              <div class="tax-cost-item-title">年利润总额:</div>
              <div class="tax-cost-item-value">
                {{ cost.info.totalAnnualProfit }}
              </div>
            </div>
            <div class="tax-cost-item">
              <div class="tax-cost-item-title">暂估成本:</div>
              <div class="tax-cost-item-value">
                {{ cost.info.tentativeCost }}
              </div>
            </div>
            <div class="tax-cost-item">
              <div class="tax-cost-item-title">去暂估年利润费用:</div>
              <div class="tax-cost-item-value">
                {{ cost.info.subtractTentativeCostTotalAnnualProfit }}
              </div>
            </div>
          </div>
          <!-- <div class="none">暂无数据</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as archivesData from "@/views/archives/js/archives.js";
import * as baseData from "@/assets/js/baseData.js";
export default {
  name: "wechat-index",

  data() {
    return {
      client: {},
      baseInfo: {},
      clientInfo: {
        tip: [],
      },
      tempData: {},
      // 合同期限
      contractDeadLine: {
        loading: false,
        moreFlag: false,
        list: [],
      },
      // 资质到期
      qualification: {
        loading: false,
        moreFlag: false,
        list: [],
      },
      // 档案
      archives: {
        loading: false,
        list: [],
      },
      // 快递
      expressage: {
        loading: false,
        moreFlag: false,
        list: [],
      },
      // 任务
      task: {
        loading: false,
        date: this.$formatTime(new Date(), "YYYY-MM"),
        list: [],
      },
      // 企业流水
      taxStatement: {
        loading: false,
        year: this.$formatTime(new Date(), "YYYY"),
        options: [],
        tableMenu: [],
        list: {},
      },
      // 财务数据
      cost: {
        date: this.$formatTime(new Date() - 31 * 24 * 3600 * 1000, "YYYY-MM"),
        info: {},
      },
    };
  },
  methods: {
    // 获取流水设置项
    queryByMarkByTaxStatement() {
      this.$request(
        "get",
        "/dataDictionary/queryByMark?mark=taxStatement",
        {},
        (data) => {
          this.taxStatement.options = data;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    // 获取当前季度
    getCurrentQuarter() {
      let monthList = baseData.getMonthOptions();
      let month = new Date().getMonth(); // 获取当前月份 (0-11)
      let quarter = [];
      if (month >= 0 && month <= 2) {
        quarter = [0, 1, 2]; // 第一季度
      } else if (month >= 3 && month <= 5) {
        quarter = [3, 4, 5]; // 第二季度
      } else if (month >= 6 && month <= 8) {
        quarter = [6, 7, 8]; // 第三季度
      } else {
        quarter = [9, 10, 11]; // 第四季度
      }
      return monthList.filter((item, index) => quarter.includes(index));
    },
    // 获取客户信息 标签
    getClientInfo() {
      let requestData = {
        clientId: this.client.id,
        companyId: "4a8add03df734b3bba7bcbb9f488f50f",
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        "/client/showClientInfoByIDNotAuthor",
        requestData,
        (data) => {
          this.clientInfo = data.data;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    // 获取合同期限
    getContractDeadLine(pageSize) {
      this.contractDeadLine.loading = true;
      let requestData = {
        clientId: this.client.id,
        companyId: "4a8add03df734b3bba7bcbb9f488f50f",
        userId: this.baseInfo.userId,
        pageSize,
        currentPage: 1,
      };
      this.$request(
        "post",
        "/contract/queryForWechatSidebar",
        requestData,
        (data) => {
          this.contractDeadLine.list = data.records;
          if (this.contractDeadLine.list.length > 0) {
            this.contractDeadLine.list.forEach((item) => {
              if (Number.parseFloat(item.accruedAssets) === 0) {
                // 未收款
                item.collectionStatus = "未收款";
              } else if (
                Number.parseFloat(item.accruedAssets) > 0 &&
                Number.parseFloat(item.accruedAssets) <
                  Number.parseFloat(item.money)
              ) {
                // 部分收款
                item.collectionStatus = "部分收款";
              } else if (Number.parseFloat(item.auditedIncome) === 0) {
                // 已全部收款
                item.collectionStatus = "已全部收款";
              }
            });
          }
          this.contractDeadLine.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.contractDeadLine.loading = false;
        }
      );
    },
    contractDeadLineRowClassName({ row, rowIndex }) {
      let endTime = new Date(row.endTime).getTime();
      let nowTime = new Date().getTime();
      if (nowTime > endTime) {
        // 到期时间 小于现在时间 标红处理
        return "contractDeadLineRowClassName";
      }
    },
    // 获取资质到期
    getQualification(pageSize) {
      this.qualification.loading = true;
      let requestData = {
        clientId: this.client.id,
        companyId: "4a8add03df734b3bba7bcbb9f488f50f",
        userId: this.baseInfo.userId,
        pageSize,
        currentPage: 1,
      };
      this.$request(
        "post",
        "/qualification/getQualificationByClientForWechatSidebar",
        requestData,
        (data) => {
          this.qualification.list = data.records;
          this.qualification.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.qualification.loading = false;
        }
      );
    },
    // 获取档案信息
    getArchives(pageSize) {
      this.archives.loading = true;
      let requestData = {
        clientId: this.client.id,
        pageSize,
        currentPage: 1,
      };
      this.$request(
        "post",
        "/archivesInfo/listByClientIdForWechatSidebar",
        requestData,
        (data) => {
          this.archives.list = archivesData.transformArchivesInfoTableData(
            data.records
          );
          this.archives.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.archives.loading = false;
        }
      );
    },
    // 获取快递信息
    getExpressage(pageSize) {
      this.expressage.loading = true;
      let requestData = {
        clientId: this.client.id,
        pageSize,
        currentPage: 1,
      };
      this.$request(
        "post",
        "/expressOrder/queryOrderForWechatSidebar",
        requestData,
        (data) => {
          this.expressage.list = data.records;
          this.expressage.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.expressage.loading = false;
        }
      );
    },
    // 获取日程信息
    getTask() {
      this.task.loading = true;
      let requestData = {
        clientId: this.client.id,
        userId: this.baseInfo.userId,
        month: this.task.date, // 筛选项 月份
        currentPage: 1,
        pageSize: 5,
      };
      this.$request(
        "post",
        "/schedule/getByClient",
        requestData,
        (data) => {
          this.task.list = data.records;
          this.task.list.forEach((item) => {
            switch (item.scheduleStatus) {
              case "1":
                item.statusName = "未完成";
                item.color = "#409EFF";
                break;
              case "2":
                item.statusName = "进行中";
                item.color = "#67C23A";
                break;
              case "3":
                item.statusName = "已结束";
                item.color = "#F56C6C";
                break;
              case "4":
                item.statusName = "已逾期";
                item.color = "#F56C6C";
                break;
              default:
                break;
            }
          });
          this.task.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.task.loading = false;
        }
      );
    },
    // 获取企业流水
    getTaxStatement() {
      this.taxStatement.loading = true;
      let requestData = {
        clientId: this.client.id,
        userId: this.baseInfo.userId,
        year: this.taxStatement.year,
      };
      this.$request(
        "post",
        "/businessJournal/getForWechatSidebar",
        requestData,
        (data) => {
          this.taxStatement.list = [data];
          this.taxStatement.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.taxStatement.loading = false;
        }
      );
    },
    // 获取财务数据
    getCostInfo() {
      let requestData = {
        clientId: this.client.id,
        userId: this.baseInfo.userId,
        date: this.cost.date,
      };
      this.$request(
        "post",
        "/enterpriseCost/getForWechatSidebar",
        requestData,
        (data) => {
          this.cost.info = data;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    handleClick(item, flag) {
      this.tempData = item;
      let requestData = {};
      switch (flag) {
        case "showQualification":
          // 查看资质
          window.open(this.tempData.file.path, "_blank");
          break;
        case "completionTask":
          // 完成日程
          requestData = {
            scheduleId: this.tempData.scheduleId,
            userId: this.baseInfo.userId,
            clientId: this.client.id,
            remarks: "",
          };
          this.$request(
            "post",
            "/scheduleComplete/add",
            requestData,
            (data) => {
              this.$message.success("完成日程成功!");
              this.getTask(); // 重新获取日程信息
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        default:
          break;
      }
    },
    changeItem(event, row, value, index) {
      let requestData = {
        clientId: row.clientId, // 客户id
        id: row.id, // id
        year: this.taxStatement.year, // id
      };
      requestData[value] = event;
      this.$request(
        "post",
        "/businessJournal/saveOrUpdate",
        requestData,
        (data) => {
          this.$message.success("编辑成功!");
          // this.tableData[index].id = data;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    toMore(flag, pageSize) {
      switch (flag) {
        case "contractDeadLine":
          // 合同期限
          this.getContractDeadLine(pageSize);
          break;
        case "archives":
          // 档案
          this.getArchives(pageSize);
          break;
        case "qualification":
          // 资质到期
          this.getQualification(pageSize);
          break;
        case "expressage":
          // 快递
          this.getExpressage(pageSize);
          break;
        default:
          break;
      }
      this[flag].moreFlag = !this[flag].moreFlag;
    },
    initData(clientId, userId) {
      this.client.id = clientId;
      this.baseInfo.userId = userId;
      // this.client.id = "0aecb0c585504c84a700b2156753c74c";
      // this.baseInfo.userId = "fc2477a8b74c4184a9270a22cf40b0f0";
      this.taxStatement.tableMenu = this.getCurrentQuarter();
      this.getClientInfo(); // 获取客户信息
      this.getContractDeadLine(3); // 获取合同期限
      this.getQualification(3); // 获取资质到期
      this.getTaxStatement(); // 获取企业流水
      this.getArchives(3); // 获取档案信息
      this.getExpressage(3); // 获取快递信息
      this.getTask(); // 获取日程信息
      this.queryByMarkByTaxStatement(); // 获取流水设置项
      this.getCostInfo(); // 获取财务数据
    },
  },
};
</script>

<style lang="less" scoped>
#wechat-index {
  width: 100%;
  min-height: 100%;
  padding: 16px 12px;
  box-sizing: border-box;
  background: #f4f6f9;
  .wechat-client-content-index {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .wechat-client-content-index-line {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      background: #fff;
      box-shadow: 0 1px 4px 0 hsla(0, 0%, 60%, 0.3);
      padding: 16px 12px;
      border-radius: 6px;
      margin-bottom: 12px;
      .wechat-client-content-index-line-top {
        width: 100%;
        height: 20px;
        display: flex;
        align-items: center;
        font-size: 13px;
        .wechat-client-content-index-line-title {
          width: auto;
          height: 100%;
          padding: 0 5px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          &::before {
            content: "";
            width: 2px;
            height: 70%;
            margin-right: 5px;
            background-image: linear-gradient(#40a3ff, #40a3ff);
          }
        }
        .wechat-client-content-index-line-time {
          width: 180px;
          height: 30px;
        }
        .wechat-client-content-index-line-but {
          width: 70px;
          height: 25px;
          margin-right: 5px;
          border: 1px solid #ff874b;
          color: #ff874b;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
        }
        .active {
          flex: 1;
        }
      }
      .wechat-client-content-index-line-value {
        width: 100%;
        height: auto;
        // display: flex;
        // flex-wrap: wrap;
        font-size: 11px;
        margin: 5px;
        margin-top: 10px;
        .tax-cost {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          .tax-cost-item {
            width: 50%;
            display: flex;
            padding: 3px 0;
            box-sizing: border-box;
            .tax-cost-item-title {
              padding-right: 5px;
              box-sizing: border-box;
            }

            &:nth-child(2n) {
              border-left: 1px solid #c0c0c0;
              padding-left: 10px;
              box-sizing: border-box;
            }
          }
        }
        .tip {
          width: auto;
          height: 20px;
          padding: 3px 8px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #5f5f5f25;
          margin: 3px;
        }
        .tip-border {
          border: 1px solid #333;
          background: none;
        }
        .text {
          width: 100%;
          height: 40px;
          display: flex;
          align-items: center;
          span {
            color: #ff874b;
            margin-left: 5px;
          }
        }
        .table {
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: column;
          .line {
            width: 98%;
            height: 30px;
            border-bottom: 1px solid #d1d3d8;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 11px;
            div {
              flex: 1;
              width: 0;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .head {
            background-color: #d1d3d84f;
          }
        }
        .none {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          color: #999;
        }
        .more {
          width: 100%;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          color: #999;
        }
        /deep/ .el-table .el-table__cell {
          padding: 8px 0;
        }
        .baseColor {
          color: @baseColor;
        }
        /deep/.el-table .contractDeadLineRowClassName {
          background: #fef0f0;
        }
        .taxStatement {
          /deep/ .el-select [name="已授权"] {
            color: #67c23a;
          }
          /deep/ .el-select [name="已收"] {
            color: #67c23a;
          }
          /deep/ .el-select [name="季收"] {
            color: #e6a23c;
          }
          /deep/ .el-input__inner {
            border: 0;
            background-color: #fff;
          }
          /deep/ .el-input__suffix {
            display: none;
          }
        }
      }
    }
  }
}
</style>
