<template>
  <div id="bind-client-table">
    <div class="bind-client-table-line">
      <div class="bind-client-table-line-input">
        <div class="item">
          <el-input
            placeholder="请输入企业微信客户群名称"
            v-model="search.clientName"
            @keyup.enter.native="search(1)"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="searchData(1)"
            ></el-button>
          </el-input>
        </div>
        <div class="item">
          <el-select
            v-model="search.isBind"
            placeholder="请选择绑定状态"
            filterable
            clearable
            @change="searchData(1)"
          >
            <el-option label="未绑定" value="0"> </el-option>
            <el-option label="已绑定" value="1"> </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="bind-client-table-line">
      <el-table
        :data="value.tableData"
        border
        style="width: 100%"
        height="50vh"
      >
        <el-table-column type="index" width="50" label="序号" align="center">
        </el-table-column>
        <el-table-column prop="groupName" label="微信群名" align="center">
        </el-table-column>
        <el-table-column prop="groupOwnerName" label="群主" align="center">
        </el-table-column>
        <el-table-column prop="groupNotice" label="群公告" align="center">
        </el-table-column>
        <el-table-column
          prop="groupCreateTime"
          label="群创建时间"
          align="center"
        >
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="primary"
              plain
              @click="bindWechatClient(scope.row)"
              size="mini"
              :disabled="scope.row.isBind == '1' || scope.row.isBind == 1"
              >绑定</el-button
            >
            <el-button
              type="success"
              plain
              @click="updateWechatClient(scope.row)"
              size="mini"
              >更新</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="bind-client-table-line">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="value.pageData.currentPage"
        :page-size="value.pageData.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="value.pageData.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: ["bindClientTableData"],
  data() {
    return {
      value: this.bindClientTableData,
      search: {
        clientName: "",
        isBind: "0",
      },

      // value: {
      //   clientName: "张三",
      //   tableData: [
      //     {
      //       name: "张三",
      //       type: "微信用户", // 外部联系人的类型，1表示该外部联系人是微信用户，2表示该外部联系人是企业微信用户 (后台转)
      //       externalUserId: "", // 外部联系人的userID  企业微信获取
      //     },
      //   ],
      // },
      input: "",
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
    };
  },
  watch: {
    expendDetailsData() {
      //父组件值变化->>子组件也变化
      this.value = this.expendDetailsData;
    },
    value(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("show", newvalue);
    },
  },
  methods: {
    bindWechatClient(data) {
      this.$emit("bindWechatClient", data);
    },
    updateWechatClient(data) {
      this.$emit("updateWechatClient", data);
    },
    searchData(flag) {
      this.$emit("searchData", { value: this.search, flag: flag });
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.value.pageData.pageSize = val;
      this.$emit("getALlWechatGroupClientList");
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.value.pageData.currentPage = val;
      this.$emit("getALlWechatGroupClientList");
    },
  },
  created() {
    console.log("value", this.value);
  },
};
</script>

<style lang="less" scoped>
#bind-client-table {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .bind-client-table-line {
    margin-top: 5px;
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
      width: 4px; /* 设置滚动条的宽度 */
      height: 4px; /* 设置滚动条的高度*/
    }

    .bind-client-table-line-input {
      display: flex;
      .item {
        width: 220px;
        margin-right: 10px;
      }
    }
  }
}
</style>
