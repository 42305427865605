/**
 * 导入税务详情数据
 */
export function importTaxDialogData(this_) {
    return [
        {
            title: '选择月份',
            type: "time_month",
            value: ''
        },
        {
        type: "improt_resources",
        value: {
            url: "",
            text: "",
            action: this_.$fileBaseurl + "/uploadImageByMultipartFile",
            uploadParameter: {
                systemName: "finance",
                title: "导入税务信息",
            },
            headers: {
                token: "95931b371eb64f4a9c862983ca8a5d15",
            },
            modelType: "taxInfoMode"
        },
    },
 ]
}

/**
 * 导入社保统计数据数据
 */
export function importSocialSecurityData(this_) {
    return [
        {
        type: "improt_resources",
        value: {
            url: "",
            text: "",
            action: this_.$fileBaseurl + "/uploadImageByMultipartFile",
            uploadParameter: {
                systemName: "finance",
                title: "导入社保信息",
            },
            headers: {
                token: "95931b371eb64f4a9c862983ca8a5d15",
            },
            modelType: "taxInfoMode"
        },
    },
    ]
}

/**
 * 导入企业成本
 */
export function importTaxCostData(this_) {
  return [
      {
      type: "improt_resources",
      value: {
          url: "",
          text: "",
          action: this_.$fileBaseurl + "/uploadImageByMultipartFile",
          uploadParameter: {
              systemName: "finance",
              title: "导入企业成本信息",
          },
          headers: {
              token: "95931b371eb64f4a9c862983ca8a5d15",
          },
          modelType: "taxInfoMode"
      },
  },
  ]
}

/**
 * 获取界面筛选数据
 * 
 */
export function taxInfoSearchData(areaList, customerSourceList) {
    let data = [
        {
            title: "公司类型",
            type: "select",
            flag: "0",
            hasSearch: false,
            key: "addedValueTaxType",
            search_value: "",
            value: [
                // { text: "张三", is_active: false },
            ],
            options: [
                { label: "全部", value: "" },
                { label: "一般纳税人", value: "一般纳税人" },
                { label: "小规模纳税人", value: "小规模纳税人" },
              ],
        },
        {
            title: "片区",
            type: "select",
            flag: "0",
            hasSearch: false,
            key: "pianqu",
            search_value: "",
            value: [
                // { text: "张三", is_active: false },
            ],
            options: areaList,
        },
        {
            title: "地址",
            type: "input",
            flag: "0",
            hasSearch: false,
            key: "address",
            value: "",
        },
        {
            title: "客户来源",
            type: "tip",
            flag: "0",
            hasSearch: true,
            key: "source",
            search_value: "",
            value: [],
            options: customerSourceList,
        },
    ]
    return data
}

/**
 * 个人社保缴纳详情数据
 * 
 */
export function companySocialProps() {
    let data = [
        {
            prop: "paymentDate",
            label: "缴费月份",
        },
        {
            prop: "totalPaymentAmount",
            label: "单位+个人缴费",
        },
        {
            prop: "unitPaymentAmount",
            label: "单位缴费",
        },
        {
            prop: "personalPaymentAmount",
            label: "个人缴费",
        },
        {
            prop: "medicalAmountOfUnit",
            label: "医疗单位",
        },
        {
            prop: "medicalAmountOfPersonal",
            label: "医疗个人",
        },
        {
            prop: "retirementAmountOfUnit",
            label: "养老单位",
        },
        {
            prop: "retirementAmountOfPersonal",
            label: "养老个人",
        },
        {
            prop: "unemploymentAmountOfUnit",
            label: "失业单位",
        },
        {
            prop: "unemploymentAmountOfPersonal",
            label: "失业个人",
        },
        {
            prop: "industrialInjuryAmountOfUnit",
            label: "工伤", // 工商单位
        },
    ]
    return data
}

/**
 * 企业流水表头数据
 */
export function taxStatementTableMenu() {
    return [
        {
          prop: "clientName",
          label: "客户名称",
          width: "200",
          fixed: true,
        },
        {
          prop: "companyType",
          label: "公司类型",
          width: "100",
          fixed: true,
        },
        {
          prop: "bankName",
          label: "开户银行",
          width: "150",
          fixed: true,
        },
        {
          prop: "bankAuth",
          label: "银行授权",
          width: "110",
          type: "select-bank",
          fixed: true,
        },
        {
          prop: "host",
          label: "主办",
          width: "120",
          fixed: true,
        },
        {
          prop: "consultant",
          label: "顾问",
          width: "120",
          fixed: true,
        },
        {
          prop: "january",
          label: "1月流水",
          width: "100",
          type: "select-salary",
        },
        {
          prop: "february",
          label: "2月流水",
          width: "100",
          type: "select-salary",
        },
        {
          prop: "march",
          label: "3月流水",
          width: "100",
          type: "select-salary",
        },
        {
          prop: "april",
          label: "4月流水",
          width: "100",
          type: "select-salary",
        },
        {
          prop: "may",
          label: "5月流水",
          width: "100",
          type: "select-salary",
        },
        {
          prop: "june",
          label: "6月流水",
          width: "100",
          type: "select-salary",
        },
        {
          prop: "july",
          label: "7月流水",
          width: "100",
          type: "select-salary",
        },
        {
          prop: "august",
          label: "8月流水",
          width: "100",
          type: "select-salary",
        },
        {
          prop: "september",
          label: "9月流水",
          width: "100",
          type: "select-salary",
        },
        {
          prop: "october",
          label: "10月流水",
          width: "100",
          type: "select-salary",
        },
        {
          prop: "november",
          label: "11月流水",
          width: "100",
          type: "select-salary",
        },
        {
          prop: "december",
          label: "12月流水",
          width: "100",
          type: "select-salary",
        },
      ]

}

/**
 * 企业流水表头数据 待办抽屉
 */
export function taxStatementTableMenuByDrawer() {
  return [
      {
        prop: "companyType",
        label: "公司类型",
        width: "100",
      },
      {
        prop: "bankName",
        label: "开户银行",
        width: "150",
      },
      {
        prop: "bankAuth",
        label: "银行授权",
        width: "110",
        type: "select-bank",
      },
      {
        prop: "host",
        label: "主办",
        width: "120",
      },
      {
        prop: "consultant",
        label: "顾问",
        width: "120",
      },
      {
        prop: "january",
        label: "1月流水",
        width: "100",
        type: "select-salary",
      },
      {
        prop: "february",
        label: "2月流水",
        width: "100",
        type: "select-salary",
      },
      {
        prop: "march",
        label: "3月流水",
        width: "100",
        type: "select-salary",
      },
      {
        prop: "april",
        label: "4月流水",
        width: "100",
        type: "select-salary",
      },
      {
        prop: "may",
        label: "5月流水",
        width: "100",
        type: "select-salary",
      },
      {
        prop: "june",
        label: "6月流水",
        width: "100",
        type: "select-salary",
      },
      {
        prop: "july",
        label: "7月流水",
        width: "100",
        type: "select-salary",
      },
      {
        prop: "august",
        label: "8月流水",
        width: "100",
        type: "select-salary",
      },
      {
        prop: "september",
        label: "9月流水",
        width: "100",
        type: "select-salary",
      },
      {
        prop: "october",
        label: "10月流水",
        width: "100",
        type: "select-salary",
      },
      {
        prop: "november",
        label: "11月流水",
        width: "100",
        type: "select-salary",
      },
      {
        prop: "december",
        label: "12月流水",
        width: "100",
        type: "select-salary",
      },
    ]

}

/**
 * 企业园区表头数据
 */
export function taxParkTableMenu() {
  return [
      {
        prop: "clientName",
        label: "客户名称",
        width: "250",
        fixed: true
      },
      {
        prop: "localPark",
        label: "所在园区",
        width: "120",
        type: "select-park",
        fixed: true
      },
      {
        prop: "host",
        label: "主办",
        width: "160",
        fixed: true
      },
      {
        prop: "consultant",
        label: "顾问",
        width: "160",
        fixed: true
      },
      {
        prop: "january",
        label: "1月纳税",
        width: "150",
        type: "select-salary",
        index: 12
      },
      {
        prop: "february",
        label: "2月纳税",
        width: "100",
        type: "select-salary",
        index: 1
      },
      {
        prop: "march",
        label: "3月纳税",
        width: "100",
        type: "select-salary",
        index: 2
      },
      {
        prop: "april",
        label: "4月纳税",
        width: "100",
        type: "select-salary",
        index: 3
      },
      {
        prop: "may",
        label: "5月纳税",
        width: "100",
        type: "select-salary",
        index: 4
      },
      {
        prop: "june",
        label: "6月纳税",
        width: "100",
        type: "select-salary",
        index: 5
      },
      {
        prop: "july",
        label: "7月纳税",
        width: "100",
        type: "select-salary",
        index: 6
      },
      {
        prop: "august",
        label: "8月纳税",
        width: "100",
        type: "select-salary",
        index: 7
      },
      {
        prop: "september",
        label: "9月纳税",
        width: "100",
        type: "select-salary",
        index: 8
      },
      {
        prop: "october",
        label: "10月纳税",
        width: "100",
        type: "select-salary",
        index: 9
      },
      {
        prop: "november",
        label: "11月纳税",
        width: "100",
        type: "select-salary",
        index: 10
      },
      {
        prop: "december",
        label: "12月纳税",
        width: "100",
        type: "select-salary",
        index: 11
      },
    ]

}

/**
 * 企业成本表头数据
 */
export function taxIncomeTableMenu() {
  return [
      {
        prop: "clientName",
        label: "客户名称",
        width: "220",
        fixed: true
      },
      {
        prop: "companyType",
        label: "公司类型",
        width: "110",
        fixed: true
      },
      {
        prop: "host",
        label: "主办",
        width: "140",
      },
      {
        prop: "consultant",
        label: "顾问",
        width: "140",
      },
      {
        prop: "nearlyYear",
        label: "近12个月营业收入",
        width: "160",
        sortable: true
      },
      {
        prop: "annualOperatingIncome",
        label: "年营业收入",
        width: "130",
        sortable: true
      },
      {
        prop: "annualOperatingCost",
        label: "年营业成本",
        width: "130",
        sortable: true
      },
      {
        prop: "annualOperatingExpense",
        label: "年营业费用",
        width: "130",
        sortable: true
      },
      {
        prop: "totalAnnualProfit",
        label: "年利润总额",
        width: "130",
        sortable: true
      },
      {
        prop: "tentativeCost",
        label: "暂估成本",
        width: "150", 
        type: 'input',
        sortable: true
      },
      {
        prop: "subtractTentativeCostTotalAnnualProfit",
        label: "去暂估年利润费用",
        width: "160",
        sortable: true
      },
      {
        prop: "chargeUpStatus",
        label: "记账状态",
        width: "160",
        type: 'select',
        options: [
          {
            label: '未完成',
            value: '1'
          },
          {
            label: '已完成',
            value: '2'
          },
        ]
      },
      {
        prop: "checkStatus",
        label: "审核状态",
        width: "160",
        type: 'select',
        options: [
          {
            label: '未审核',
            value: '1'
          },
          {
            label: '未通过',
            value: '2'
          },
          {
            label: '已通过',
            value: '3'
          },
        ]
      },
    ]
}
/**
 * 企业成本表头数据 待办抽屉
 */
export function taxIncomeTableMenuByDrawer() {
  return [
    {
      prop: "companyType",
      label: "公司类型",
      width: "110",
      fixed: true
    },
    {
      prop: "host",
      label: "主办",
      width: "140",
    },
    {
      prop: "consultant",
      label: "顾问",
      width: "140",
    },
    
    {
      prop: "nearlyYear",
      label: "近12个月营业收入",
      width: "160",
      sortable: true
    },
    {
      prop: "annualOperatingIncome",
      label: "年营业收入",
      width: "130",
      sortable: true
    },
    {
      prop: "annualOperatingCost",
      label: "年营业成本",
      width: "130",
      sortable: true
    },
    {
      prop: "annualOperatingExpense",
      label: "年营业费用",
      width: "130",
      sortable: true
    },
    {
      prop: "totalAnnualProfit",
      label: "年利润总额",
      width: "130",
      sortable: true
    },
    {
      prop: "tentativeCost",
      label: "暂估成本",
      width: "150", 
      type: 'input',
      sortable: true
    },
    {
      prop: "subtractTentativeCostTotalAnnualProfit",
      label: "去暂估年利润费用",
      width: "160",
      sortable: true
    },
    {
      prop: "chargeUpStatus",
      label: "记账状态",
      width: "160",
      type: 'select',
      options: [
        {
          label: '未完成',
          value: '1'
        },
        {
          label: '已完成',
          value: '2'
        },
      ]
    },
    {
      prop: "checkStatus",
      label: "审核状态",
      width: "160",
      type: 'select',
      options: [
        {
          label: '未审核',
          value: '1'
        },
        {
          label: '未通过',
          value: '2'
        },
        {
          label: '已通过',
          value: '3'
        },
      ]
    },
  ]
}

/**
 * 企业流水月份状态选项列表
 */
export function taxStatementMonthStatus(_this) {
  let monthList = _this.$systemBaseData.getMonthOptions()
  monthList.forEach(item => {
    item.children = []
  })
  let ccc = []
  _this.$request(
    "get",
    "/dataDictionary/queryByMark?mark=taxStatement",
    {},
    (data) => {
      ccc = data;
      ccc.forEach(item => {
        monthList.forEach(item2 => {
          item2.children.push(
            {
              label: item.label,
              value: item.value
            }
          )
        })
      })
    },
    (errorMessage) => {
      this.$message.error(errorMessage);
    }
  );
  return monthList
}
